/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-facebook': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.698C13.256.238 11.11.018 9 0 6.89.018 4.744.239 3.568.68 1.39 1.51.67 3.677.52 4.226l-.008.028a8.37 8.37 0 00-.023.09l-.032.14a18.33 18.33 0 00-.455 4.352c-.018 1.342.085 2.835.44 4.319a17.913 17.913 0 00.093.364c.18.623.921 2.686 3.033 3.492 1.176.441 3.323.662 5.432.68 2.11-.018 4.256-.22 5.432-.68 2.176-.83 2.897-2.996 3.048-3.546l.008-.028a9.173 9.173 0 00.055-.23c.35-1.5.473-3.013.455-4.352.018-1.353-.087-2.861-.433-4.341l-.036-.15a7.825 7.825 0 00-.024-.096l-.01-.036c-.157-.567-.883-2.709-3.044-3.534z" _fill="#898B9B"/><mask id="svgicon_sn-facebook_a" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.698C13.256.238 11.11.018 9 0 6.89.018 4.744.239 3.568.68 1.39 1.51.67 3.677.52 4.226l-.008.028a8.37 8.37 0 00-.023.09l-.032.14a18.33 18.33 0 00-.455 4.352c-.018 1.342.085 2.835.44 4.319a17.913 17.913 0 00.093.364c.18.623.921 2.686 3.033 3.492 1.176.441 3.323.662 5.432.68 2.11-.018 4.256-.22 5.432-.68 2.176-.83 2.897-2.996 3.048-3.546l.008-.028a9.173 9.173 0 00.055-.23c.35-1.5.473-3.013.455-4.352.018-1.353-.087-2.861-.433-4.341l-.036-.15a7.825 7.825 0 00-.024-.096l-.01-.036c-.157-.567-.883-2.709-3.044-3.534z" _fill="#898B9B"/></mask><g mask="url(#svgicon_sn-facebook_a)"><path pid="2" d="M9.343 11.166V18h2.839v-6.834h2.332l.374-2.79h-2.706V6.439c0-.542.446-.98.997-.98h1.753v-2.51h-2.45c-1.733 0-3.139 1.382-3.139 3.086v2.343H7v2.79h2.343z" _fill="#fff"/></g>'
  }
})
